import React, { useRef, useState, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
};

const BaciJumbotron = (props) => {
  const { image, h1, h2, body, hideslash, video } = props;
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute('muted', ''); // leave no stones unturned :)
        player.autoplay = true;
        player.loop = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = 'none';
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  return (
    <Jumbotron
      className="jumbotron--header jumbotron-video-header"
      style={{ backgroundImage: `url(${image})` }}
    >
      {video &&
        (shouldUseImage ? (
          <img src={image} alt="Muted Video" />
        ) : (
          <div
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
             <video
               loop
               muted
               autoplay
               playsinline
               preload="metadata"
             >
             <source src="${video}" type="video/mp4" />
             </video>`,
            }}
          />
        ))}
      <div className="overlay" />
      <Container>
        {!hideslash && (
          <img
            src="/img/logo-white.svg"
            alt="Cafe Baci"
            className="baci-logo"
          />
        )}
        {h1 && <h1 dangerouslySetInnerHTML={{ __html: h1 }} />}
        {h2 && <h2 dangerouslySetInnerHTML={{ __html: h2 }} />}
        {body && <p>{body}</p>}
      </Container>
    </Jumbotron>
  );
};

export default BaciJumbotron;
