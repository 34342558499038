import React from 'react';
import { Menu } from 'react-feather';
import { Row, Container, Nav, Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../Services/menuServices';

const Navigation = () => {
  return (
    <header id="header">
      <div className="nav-bg" />
      <Container>
        <Row className="align-items-center" id="navrow">
          {/* Dropdown nav */}
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <Menu
                size="30"
                className="menu-burger-white"
                as={CustomToggle}
                id="dropdown-custom-components-burger"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} className="mobile-menu">
              <Nav className="flex-column">
                <Dropdown.Item href="#menu">Menu</Dropdown.Item>
                <Dropdown.Item href="#about">About Us</Dropdown.Item>
                <Dropdown.Item
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://cafebaci.instagift.com/"
                >
                  {' '}
                  Gift Cards
                </Dropdown.Item>
                <Dropdown.Item href="#reservation">
                  Make a Reservation
                </Dropdown.Item>
              </Nav>
            </Dropdown.Menu>
          </Dropdown>

          <a href="/" id="logo" className="baci-logo logo display-logo">
            <img src="/img/logo-black.svg" alt="Cafe Baci Logo" />
          </a>

          <Nav className="justify-content-end">
            <Nav.Item>
              <Nav.Link href="#menu">Menu</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="#about">About</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://cafebaci.instagift.com/"
              >
                Gift Cards
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link className="nav--contact" href="#reservation">
                Make a Reservation
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
      </Container>
    </header>
  );
};

export default Navigation;
