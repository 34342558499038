import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { ArrowRight } from 'react-feather';

const MenuSection = () => {
  return (
    <Container className="homepage-cloud-stories">
      <h2 id="menu">Menus</h2>
      <Row>
        <Col sm={12} md={12} lg={4}>
          <Card className="h-100">
            <Card.Img variant="top" src="/img/slide2.jpg" />
            <Card.Body>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="/menus/dinner_menu2020.pdf"
                className="button inverse"
              >
                Dinner Menu
                <span>
                  <ArrowRight />
                </span>
              </a>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card className="h-100">
            <Card.Img variant="top" src="/img/dessert.jpg" />
            <Card.Body>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="/menus/dessert.png"
                className="button inverse"
              >
                Dessert Menu
                <span>
                  <ArrowRight />
                </span>
              </a>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card className="h-100">
            <Card.Img variant="top" src="/img/cocktails.jpg" />
            <Card.Body>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="/menus/cafebaci-regionallist.pdf"
                className="button inverse"
              >
                Wine and Spirits
                <span>
                  <ArrowRight />
                </span>
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MenuSection;
