import React from 'react';
import './main.scss';

import Navigation from './Components/Navigation';
import Homepage from './Components/Homepage.jsx';
import BaciSlider from './Components/BaciSlider';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Homepage />
    </div>
  );
}

export default App;
