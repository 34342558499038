import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

const BaciSlider = (props) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  return (
    <>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <h3>
              "Finally, an Italian restaurant that will take you back to Italy!
              As a world traveler, there are very few restaurants outside of
              Italy that come close to feeling like the real Italian cuisine
              that you enjoy in Italy. Cafe Baci is a great find, the food
              transported me back to my trips to Italy. Great atmosphere,
              amazing food and great value. 1000% recommended. A piece of Italy
              in Florida. Will be back every time I am in town."{' '}
            </h3>
            <p>Review by 773israelb on TripAdvisor, May 2020</p>
          </div>
          <div className="keen-slider__slide number-slide2">
            <h3>
              "I can only share this: if you want a great meal with locals who
              take pride in food and service, then please try Baci."{' '}
            </h3>
            <p>Review by DrMike60 on TripAdvisor, May 2020</p>
          </div>
          <div className="keen-slider__slide number-slide3">
            <h3>
              "Charming old world Italian in Sarasota. We've been frequenting
              this fine establishment now for many years. One thing for sure,
              the food, service, and ambiance is outstanding."{' '}
            </h3>
            <p> Review by Meducator on TripAdvisor, March 2020</p>
          </div>
        </div>
        {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
            />

            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </div>
      {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={`dot${currentSlide === idx ? ' active' : ''}`}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

function ArrowLeft(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={`arrow arrow--left${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  );
}

function ArrowRight(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={`arrow arrow--right${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  );
}

export default BaciSlider;
