import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Facebook, Instagram } from 'react-feather';
import Map from './Map';

const Footer = () => {
  const [hovered, setHovered] = useState(false);

  const style = hovered ? { opacity: 0.6 } : {};
  return (
    <footer>
      <Container fluid className="footer-middle">
        <Row className="justify-content-md-center">
          <Col xs={1}>
            <div className="circle insta">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://instagram.com/cafebacisarasota?igshid=15ouvfw4vta1a"
              >
                <Instagram color="white" height="40px" width="40px" />
              </a>
            </div>
          </Col>
          <Col xs={1}>
            <div className="circle fb">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/Cafe-Baci-110258495725501/"
              >
                <Facebook
                  color="white"
                  fill="white"
                  strokeWidth="0"
                  height="40px"
                  width="40px"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="footer-lower">
        <div className="footer-lower--container">
          <Row className="footer-lower--row">
            <div>
              <Map />
            </div>

            <div className="footer-address-block">
              <img
                src="/img/logo-white.svg"
                className="footer-logo"
                alt="Cafe Baci logo, white"
              />
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/Cafe+Baci/@27.3154048,-82.5294848,15z/data=!4m5!3m4!1s0x88c341b1f15976d5:0x380ec33db177a523!8m2!3d27.2969472!4d-82.5303278"
              >
                <p>4001 S. Tamiami Trail</p>
                <p>Sarasota, FL 34231</p>
              </a>
              <p className="tel">
                <a href="tel:9419214848">(941) 921-4848</a>
              </p>
            </div>
          </Row>
        </div>
      </Container>
      <Container fluid className="footer-bottom">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} md={3}>
              © Copyright {new Date().getFullYear()} Cafe Baci
            </Col>
            <Col sm={6} md={3}>
              <a target="_blank" rel="noopener noreferrer" href="/terms.html">
                Terms
              </a>
            </Col>
            <Col sm={6} md={3}>
              <a target="_blank" rel="noopener noreferrer" href="/privacy.html">
                Privacy
              </a>
            </Col>
            <Col sm={12} md={3} className="text-right">
              <div>
                <a
                  rel="noopener noreferrer"
                  href="https://rootlab.io"
                  target="_blank"
                >
                  <img
                    className="madeby"
                    alt="rootlab"
                    style={style}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    src="/img/clickme.jpg"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;
